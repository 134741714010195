import React from "react";
import "./DataPrivacy.css";
import { Helmet } from "react-helmet";

const Impressum = () => {
    return (


        <div className="data-privacy-page">
            <Helmet>
                <title>Impressum</title>
                <meta name="description" content="Die Juwelenschmiede Kerecz befindet sich in der Rudolf-Krenn-Gasse 146a, 8322 Südstudenzen, Österreich. Für individuelle Beratungen oder Anfragen ist Anton Kerecz telefonisch unter +43 664 2533419 oder per E-Mail an juwelenschmiede@kerecz.at erreichbar." />
            </Helmet>
            <div className="privacy-content">
                <div className="header-wrapper-privacy">
                    <h1>Impressum</h1>
                </div>
                <section className="privacy-section impressum">
                    <h2>Verantwortlich für den Inhalt
                    </h2>
                    <p>
                        Anton Kerecz
                    </p>
                    <p>
                        Studenzen 146a,
                    </p>
                    <p>
                        8322  Kirchberg an der Raab, Österreich
                    </p>
                    <a id="a1" href="tel:+436642533419">+43 664 2533419</a>
                    <a href="mailto:juwelenschmiede@kerecz.at">juwelenschmiede@kerecz.at </a>
                    <p>
                        UID: ATU64833844

                    </p>
                    <p>
                        FB-Nummer: FN 325347b, HG Graz
                    </p>
                </section>

                <section className="privacy-section impressum2">
                    <h2>Copyright, Marken- und Schutzrechte
                    </h2>
                    <p>
                        Alle Inhalte unterliegen dem Copyright von glick by Drift GmbH sofern nicht anders gekennzeichnet. Texte, Bilder, Grafiken, Sound, Animationen und Videos unterliegen dem
                        Schutz des Urheberrechts und anderer Schutzgesetze. Der Inhalt darf nicht zu kommerziellen Zwecken kopiert, verbreitet, verändert oder Dritten zugänglich gemacht werden.
                        Jede Nutzung, insbesondere die Speicherung in Datenbanken, Vervielfältigung, Verbreitung, Bearbeitung und jede Form von gewerblicher Nutzung sowie die Weitergabe an Dritte,
                        auch in Teilen oder in überarbeiteter Form, ist ohne Zustimmung des Betreibers bzw. des Urhebers untersagt.</p>
                    <h2>
                        Konzeption
                    </h2>
                    <p>Drift GMBH</p>
                    <a href="https://driftcom.net/">https://driftcom.net/</a>
                    <p></p>
                    <a href="mailto:mail@driftcom.net">mail@driftcom.net</a>

                    <h2>
                        Technische Umsetzung

                    </h2>
                    <p>
                        Riccardo Wolf
                    </p>
                    <a href="https://askmynet.com/">https://askmynet.com/</a>
                    <p></p>
                    <a href="mailto:riccardo.wolf@askmynet.com">riccardo.wolf@askmynet.com</a>
                </section>
            </div>
        </div>
    );
};

export default Impressum;
