import React from "react";
import "./DataPrivacy.css";
import { Helmet } from "react-helmet";

const DataPrivacy = () => {
    return (
        <div className="data-privacy-page">
            <Helmet>
        <title>Privacy</title>
        <meta name="description" content="Die Juwelenschmiede Kerecz befindet sich in der Rudolf-Krenn-Gasse 146a, 8322 Südstudenzen, Österreich. Für individuelle Beratungen oder Anfragen ist Anton Kerecz telefonisch unter +43 664 2533419 oder per E-Mail an juwelenschmiede@kerecz.at erreichbar."/>
      </Helmet>
            <div className="privacy-content">
                <div className="header-wrapper-privacy">
                    <h1>Datenschutzerklärung</h1>
                </div>
                <section className="privacy-section">
                    <h2>Datenschutz</h2>
                    <p>
                        Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst, deshalb
                        behandeln wir Ihre personenbezogenen Daten vertraulich und
                        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
                        Datenschutzerklärung.
                    </p>
                    <p>
                        Die Nutzung unserer Website ist in der Regel ohne Angabe
                        personenbezogener Daten möglich. Soweit auf unseren Seiten
                        personenbezogene Daten (beispielsweise Name, Anschrift oder
                        E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
                        auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                    </p>
                    <p>
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                    </p>
                </section>


                <section className="privacy-section">
                    <h2>Google Analytics</h2>
                    <p>
                        Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Google Analytics verwendet so genannte „Cookies“.
                    </p>
                </section>

                <section className="privacy-section">
                    <h2>Kontakt - Formular</h2>
                    <p>
                        Wenn Sie uns per Formular Anfragen zukommen lassen, werden Ihre Angaben aus dem Formular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert.
                    </p>
                </section>

                <section className="privacy-section">
                    <h2>SSL Verschlüsselung</h2>
                    <p>
                        Diese Seite nutzt aus Gründen der Sicherheit eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                    </p>
                </section>
                <section className="privacy-section">
                    <h2>Recht auf Auskunft, Löschung, Sperrung</h2>
                    <p>
                        Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default DataPrivacy;
