import React from 'react';
import './VideoOverlay.css';

const VideoOverlay = ({ showOverlay, toogleOverlay }) => {



    
    return (
        showOverlay && (
            <div className="video-overlay" onClick={toogleOverlay}>
                <iframe
                    className="youtube-video"
                    src="https://www.youtube.com/embed/fsJI9sWFzmM?autoplay=1"
                    title="YouTube Video Player"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                ></iframe>
            </div>
        )
    );
};

export default VideoOverlay;
