import React from 'react';
import './CategorySelector.css';

const CategorySelector = ({ categories, selectedCategory, onCategoryChange }) => {
  return (
    <div className="category-selector">
      <h2>Jewelry</h2>
      <div>
        {categories.map((category) => (
          <div
            key={category}
            className={`sub-category ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => onCategoryChange(category)}
          >
            {category}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategorySelector;
