import React from "react";
import "./DesignMainSites.css";
import Factory_picture0 from '../assets/pictures/Factory/Factory0.JPG';
import Factory_picture1 from '../assets/pictures/Factory/Factory1.jpg';
import Factory_picture2 from '../assets/pictures/Factory/Factory2.jpg';
import Factory_picture3 from '../assets/pictures/Factory/Factory3.jpg';
import Factory_picture4 from '../assets/pictures/Factory/Factory4.jpg';
import Factory_picture5 from '../assets/pictures/Factory/Factory5.jpg';
import { Parallax } from 'react-scroll-parallax';
import { easeInOut } from "framer-motion";
import { Helmet } from "react-helmet";


const Factory = ({ parallaxSpeed }) => {
  return (

    <div className="content-main">
      <Helmet>
        <title>Factory</title>
        <meta name="description" content="Der Schmuckdesigner Anton Kerecz erschafft nicht einfach Schmuckstücke – er
                  kreiert persönliche Meisterwerke, die tief in den Charakter und die einzigartigen
                  Momente seiner Kunden eintauchen." />
      </Helmet>
      <Parallax className="parallax-header" speed={0} easing={easeInOut}>
        <img src={Factory_picture0} className="parallax-image" alt="Die Fabrik" />
        <div className="mainsites-header-text factory">
          <h1>Factory</h1>
        </div>
      </Parallax>

      <Parallax speed={parallaxSpeed} className="parallax-content" easing={easeInOut}>
        <div className="mainsiteswrapper">
          <section className="mainsites-content-section">
            <section className="content-section vertical-layout">
              <div className="text-block">
                <h2>Handmade for…
                </h2>
              </div>
              <div className="image-block vertical">
                <img src={Factory_picture1} alt="Handgemacht für" />
              </div>
              <div className="text-block">
                <p>Der Schmuckdesigner Anton Kerecz erschafft nicht einfach Schmuckstücke – er
                  kreiert persönliche Meisterwerke, die tief in den Charakter und die einzigartigen
                  Momente seiner Kunden eintauchen.
                </p></div>
            </section>


            <div className="yellowBackground factory">
              <section className="content-section overflow">
                <div className="text-block">
                  <h2>Inspirierende Reise
                  </h2>
                  <p>
                    Für ihn ist jedes Werk eine Reise, die bei der ersten
                    Begegnung beginnt und in einem exklusiven
                    Schmuckstück mündet, das als Sinnbild der
                    Persönlichkeit seines Trägers steht.
                  </p>
                </div>
                <div className="image-block overflow">
                  <img src={Factory_picture2} alt="Inspirierende Reise" className="overflow-image" />
                </div>
              </section>
            </div>

            <section className="content-section">
              <div className="image-block smaller">
                <img src={Factory_picture3} alt="Träume werden Realität" />
              </div>
              <div className="text-block reverse">
                <h2>Träume werden Realität
                </h2>
                <p>
                  Mit feinem Gespür nimmt Herr Kerecz die Wünsche, den Stil und die verborgenen Träume seiner Kunden auf. Er lädt Sie in die ruhige Atmosphäre seiner Werkstatt ein, wo Gespräche und gemeinsames Entdecken im Vordergrund stehen.
                </p>
              </div>
            </section>

            <div className="yellowBackground">
              <section className="content-section overflow">
                <div className="text-block">
                  <h2>Idee und Handwerk</h2>
                  <p>
                    Hier entfaltet sich die Kunst des Schmucks, hier entsteht
                    ein Dialog zwischen Idee und Handwerk.
                  </p>
                </div>
                <div className="image-block overflow">
                  <img src={Factory_picture4} alt="Idee und Handwerk" className="overflow-image" />
                </div>
              </section>
            </div>


            <section className="content-section vertical-layout">
              <div className="image-block vertical">
                <img src={Factory_picture5} alt="Unique" />
              </div>
              <div className="text-block">
                <h2>Unique</h2>
                <p>
                  So verwandelt Anton Kerecz individuelle Inspirationen in zeitlose Kreationen – unverwechselbare Schmuckstücke, die das Wesen und die Geschichte seiner Kunden in einzigartiger Form bewahren.
                </p>
              </div>
            </section>
          </section>
        </div>
      </Parallax>
    </div>


  );
};

export default Factory;
