import React from 'react';
import ProductCard from './ProductCard';
import './ProductCatalog.css';

const ProductCatalog = ({ products, onSelectProduct, isHidden, height }) => {
  return (
    <div
      className={`product-catalog ${isHidden ? 'hidden' : ''}`}
      style={{ height: height ? `${height}px` : 'auto' }} // Set the height here
    >
      {products.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          onClick={() => onSelectProduct(product.id)}
        />
      ))}
    </div>
  );
};

export default ProductCatalog;
