import React, { useState, useRef, useEffect } from "react";
import './Shadow.css';

const Shadow = ({ shadowRef }) => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        shadowRef.show = () => setIsVisible(true);
        shadowRef.hide = () => setIsVisible(false);
        shadowRef.isVisible = isVisible;
    },[shadowRef, isVisible]);


    return (
        <div className={`shadow ${isVisible ? 'show' : ''}`}></div>
    );

};

export default Shadow;