import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./TrendDetail.css";
import TrendDetailButton from "../assets/Custom_Elements/TrendDetailButton";

import img1 from '../assets/pictures/TrendDetail/TRENDS_1.jpg';
import img2 from '../assets/pictures/TrendDetail/TRENDS_1.5.jpg';
import img3 from '../assets/pictures/TrendDetail/TRENDS_1.6.jpg';
import img4 from '../assets/pictures/TrendDetail/TRENDS_1.7.jpg';
import img5 from '../assets/pictures/TrendDetail/TRENDS_2.jpg';
import img6 from '../assets/pictures/TrendDetail/TRENDS_2.5.jpg';
import img7 from '../assets/pictures/TrendDetail/TRENDS_2.6.jpg';
import img8 from '../assets/pictures/TrendDetail/TRENDS_2.7.jpg';
import img9 from '../assets/pictures/TrendDetail/TRENDS_3.jpg';
import img10 from '../assets/pictures/TrendDetail/TRENDS_4.jpg';
import img11 from '../assets/pictures/TrendDetail/TRENDS_4.5.jpg';
import img12 from '../assets/pictures/TrendDetail/TRENDS_4.6.jpg';
import img13 from '../assets/pictures/TrendDetail/TRENDS_4.7.jpg';

import imgHQ1 from '../assets/pictures/TrendDetail/TRENDS_1.1.jpg';
import imgHQ2 from '../assets/pictures/TrendDetail/TRENDS_1.2.jpg';
import imgHQ3 from '../assets/pictures/TrendDetail/TRENDS_1.3.jpg';
import imgHQ4 from '../assets/pictures/TrendDetail/TRENDS_1.4.jpg';
import imgHQ5 from '../assets/pictures/TrendDetail/TRENDS_2.1.jpg';
import imgHQ6 from '../assets/pictures/TrendDetail/TRENDS_2.2.jpg';
import imgHQ7 from '../assets/pictures/TrendDetail/TRENDS_2.3.jpg';
import imgHQ8 from '../assets/pictures/TrendDetail/TRENDS_2.4.jpg';
import imgHQ9 from '../assets/pictures/TrendDetail/TRENDS_3.1.jpg';
import imgHQ10 from '../assets/pictures/TrendDetail/TRENDS_4.1.jpg';
import imgHQ11 from '../assets/pictures/TrendDetail/TRENDS_4.2.jpg';
import imgHQ12 from '../assets/pictures/TrendDetail/TRENDS_4.3.jpg';
import imgHQ13 from '../assets/pictures/TrendDetail/TRENDS_4.4.jpg';




const TrendDetail = ({ showOverlay }) => {
  const [flyOut, setFlyOut] = useState(false);
  const [flyIn, setFlyIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const [selectedImage, setSelectedImage] = useState(null); // Modal state



  const allImages = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13];
  const allImagesHQ = [imgHQ1, imgHQ2, imgHQ3, imgHQ4, imgHQ5, imgHQ6, imgHQ7, imgHQ8, imgHQ9, imgHQ10, imgHQ11, imgHQ12, imgHQ13];

  const imagesById = {
    1: allImages.slice(0, 4),
    2: allImages.slice(4, 8), 
    3: allImages.slice(8, 9), 
    4: allImages.slice(9, 13), 
  };

  const imagesByIdHQ = {
    1: allImagesHQ.slice(0, 4),
    2: allImagesHQ.slice(4, 8), 
    3: allImagesHQ.slice(8, 9), 
    4: allImagesHQ.slice(9, 13), 
  };
  

  useEffect(() => {

    setTimeout(() => {
      setFlyIn(true);
    }, 1000);
  }, []);

  const handleButtonClick = () => {
    setFlyOut(true);
    showOverlay.current();

    setTimeout(() => {
      navigate("/trend");
    }, 1000); 
  };

  const details = [
    {
      id: 1,
      date: "2023 / 09",
      title: " Bezaubernder Glanz für jeden Moment – Ohrstecker",
      text: "Die eleganten Ohrstecker aus 18 Karat Roségold vereinen zeitlose Schlichtheit mit einem Hauch von Luxus. Im Zentrum jedes Ohrsteckers funkelt ein brillant geschliffener Diamant von 0,62 ct und einem Durchmesser von 4,3 mm, der dem Schmuckstück eine zarte, aber eindrucksvolle Ausstrahlung verleiht – ideal für den Alltag und zugleich subtil genug, um sich bei jedem Anlass gut einzufügen.",
      additionalText: "Für besondere Abende, elegante Dinner oder besondere Events kann der Look durch die Ergänzungen erweitert werden: 24 weitere brillant geschliffene Diamanten von insgesamt 0,35 ct und einem Durchmesser von 8,6 mm fassen das Zentrum ein und verleihen den Ohrsteckern eine auffälligere Präsenz. So entsteht ein bezaubernder Glanz von knapp 1 ct, der die Ohrstecker in eine wahre Strahlkraft hüllt und jedem besonderen Moment Glanz verleiht.",
      images: imagesById[2],
      imagesHQ: imagesByIdHQ[2]
    },
    {
      id: 2,
      date: "2021 / 04",
      title: "Prachtvoll und majestätischer Hirschkäfer– Brosche",
      text: "Zwischen den leuchtenden Wäldern und dem schimmernden Moos erhebt sich der prachtvolle Hirschkäfer, majestätisch aus edlem 18-karätigen Weißgold geformt. Seine zarten Linien und Konturen tragen 98 funkelnde Diamanten im Brillantschliff, die in aufwendiger Pavé-Technik gefasst sind und wie Tau im Morgenlicht glitzern. Seine Augen, besetzt mit tief schimmernden braunen Diamanten, verleihen ihm einen warmen und lebendigen Ausdruck, der Ruhe und Erhabenheit ausstrahlt. Mit einer Länge von 6,0 cm und einer Breite von 3,5 cm fängt er die Kraft und Anmut der Natur ein und wird zum Sinnbild für Schutz und unerschütterliche Eleganz.",
      additionalText: "",
      images: imagesById[1],
      imagesHQ: imagesByIdHQ[1]
    },
    {
      id: 3,
      date: "2018 / 11",
      title: "Funkelnde Blume im Sonnenlicht – Ring ",
      text: "Diamanten erzählen Geschichten: Umgeben von den sanft geschwungenen Linien eines Rings aus 18-karätigem Weißgold, offenbaren sie eine stille Eleganz, die von blühendem Leben spricht. Dieses Schmuckstück, maßgefertigt nach Kundenwunsch, zeigt die subtile Pracht eines Blumenmotivs, das Bewegung und Wachstum in jedem Detail spürbar macht. Die Diamanten im Brillantschliff entfalten ihr Funkeln wie zarte Blütenblätter im Sonnenlicht, und das klare Weißgold fängt diese Dynamik ein, als wäre es ein lebendiger Rahmen. Der Ring vereint Leichtigkeit und Stärke und schafft einen Raum für eigene Geschichten, die sich wie ein stilles Flüstern im Herzen seines Trägers entfalten. ",
      additionalText: "",
      images: imagesById[3],
      imagesHQ: imagesByIdHQ[3]
    },
    {
      id: 4,
      date: "2017 / 03",
      title: "Verlorenes zum Leben erwecken – Ohrclips",
      text: "Künstler Anton Kerecz vereint edelste Materialien und präzise Handwerkskunst, um die Eleganz und Einzigartigkeit eines verlorenen Schmuckstücks neu zu erschaffen. In filigraner Arbeit fertigt er einen maßgetreuen Ohrclip in 18 Karat Weißgold, dessen Herzstück ein funkelnder Smaragd von 6,7 mm Durchmesser bildet, umrahmt von 55 brillantgeschliffenen Diamanten. Die Klarheit der Steine und die kühle Reinheit des Weißgolds heben die zeitlose Anmut des Originals hervor und bewahren zugleich seine persönliche Bedeutung. Kereczs Arbeit ist mehr als die bloße Replikation eines verlorenen Schmuckstücks – sie ist eine Hommage an das individuelle Erbe und die Schönheit, die im Detail liegt.",
      additionalText: "",
      images: imagesById[4],
      imagesHQ: imagesByIdHQ[4]
    },
  ];

  // Find the detail matching the id from the URL
  const detail = details.find((item) => item.id === parseInt(id, 10));

  if (!detail) {
    return <div>Product not found.</div>;
  }

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="trend-detail">
      <div className={`trend-detail-container ${flyIn ? 'fly-in' : ''} ${flyOut ? 'fly-out' : ''}`}>
        <div className="trend-detail-content">
          <div className="detail-header">
            <p>{detail.date}</p>
            <h1>{detail.title}</h1>
            {detail.images.length > 0 && (
              <img
                src={detail.images[0]}
                alt="Trend detail main"
                onClick={() => openModal(detail.imagesHQ[0])} // Make main image clickable
              />
            )}
          </div>
          <div className="detail-image-collection">
            {detail.images.slice(1).map((src, idx) => (
              <img
                key={idx}
                src={src}
                alt={`Trend detail ${idx + 1}`}
                onClick={() => openModal(detail.imagesHQ[idx+1])} // Make each image clickable
              />
            ))}
          </div>
          <div className="text-block">
            <p>{detail.text}</p>
            <p>{detail.additionalText}</p>
          </div>
          <TrendDetailButton onClick={handleButtonClick} />
        </div>
      </div>

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="Enlarged view" />
          </div>
        </div>
      )}
    </div>
  );
};

export default TrendDetail;