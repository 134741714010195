import React, { useState, useEffect } from 'react';
import './Transition.css'; 

const Transition = ({ showOverlay, hideOverlay }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    showOverlay.current = () => setIsVisible(true);
    hideOverlay.current = () => setIsVisible(false);
  }, [showOverlay, hideOverlay]);

  return (
    <div className={`overlay ${isVisible ? 'overlay-show' : 'overlay-hide'}`} />
  );
};

export default Transition;
