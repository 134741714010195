import React from "react";
import { Parallax } from 'react-scroll-parallax';
import "./DesignMainSites.css";
import Jewelry_picture0 from '../assets/pictures/Jewelry/Jewelry0.jpg';
import Jewelry_picture1 from '../assets/pictures/Jewelry/Jewelry1.jpg';
import Jewelry_picture2 from '../assets/pictures/Jewelry/Jewelry2.jpg';
import Jewelry_picture3 from '../assets/pictures/Jewelry/Jewelry3.jpg';
import Jewelry_picture4 from '../assets/pictures/Jewelry/Jewelry4.jpg';
import Jewelry_picture5 from '../assets/pictures/Jewelry/Jewelry5.jpg';
import { easeInOut } from "framer-motion";
import CustomLink from '../assets/Custom_Elements/CustomLink';
import { Helmet } from "react-helmet";
const JewerlyOverview = ({ showOverlay, parallaxSpeed }) => {
    return (

        <div className="content-main">
            <Helmet>
                <title>Jewelry</title>
                <meta name="description" content="Schwelgen Sie in der Auswahl. Genießen Sie das Funkeln. Verlieben Sie sich in
                                jedes einzelne Schmuckstück."/>
            </Helmet>
            <Parallax className="parallax-header" speed={0} easing={easeInOut} >
                <img src={Jewelry_picture0} alt="Hero" className="parallax-image" />
                <div className="mainsites-header-text">
                    <h1>Jewelry</h1>
                </div>
            </Parallax>
            <Parallax speed={parallaxSpeed} className="parallax-content" easing={easeInOut}>
                <div className="mainsiteswrapper">
                    <section className="mainsites-content-section">
                        <section className="content-section vertical-layout" id="vertical1">
                            <div className="text-block">
                                <h2> Wahre Liebe erwartet Sie
                                </h2>
                            </div>
                            <div className="image-block">
                                <img src={Jewelry_picture1} alt="Die Schmuckauswahl" id="fullimg" />
                            </div>
                            <div className="text-block"><p> Schwelgen Sie in der Auswahl. Genießen Sie das Funkeln. Verlieben Sie sich in
                                jedes einzelne Schmuckstück. Aber glauben Sie bloß nicht das wäre alles! Saisonale
                                Kollektionen und Maßanfertigungen bringen Ihre Wünsche und die Kreativität von
                                Anton Kerecz ans Tageslicht. </p>
                            </div>
                        </section>
                        <section className="content-section-jewelry">
                            <div className="jewelry-number">01.</div>
                            <div className="jewelry-content">
                                <div className="image-block">
                                    <img src={Jewelry_picture2} alt="Ringe mit Liebe" id="fullimg" />
                                </div>
                                <div className="text-block">
                                    <h2>Ringe</h2>
                                    <p>Entdecken Sie einzigartige Ringe, die mit Liebe hergestellt werden.</p>
                                    <CustomLink to="/jewelry?type=Ringe" showOverlay={showOverlay}>
                                        <div className="jewelry-link">
                                            Übersicht
                                        </div>
                                    </CustomLink>
                                </div>

                            </div>

                        </section>
                        <section className="content-section-jewelry">
                            <div className="jewelry-number">02.</div>
                            <div className="jewelry-content">
                                <div className="image-block">
                                    <img src={Jewelry_picture3} alt="Traum Halsketten" id="fullimg" />
                                </div>
                                <div className="text-block">
                                    <h2>Halsketten</h2>
                                    <p>Wenn Emotionen und Glücksgefühle in einer Halskette lebendig werden.</p>
                                    <CustomLink to="/jewelry?type=Halsketten" showOverlay={showOverlay} >
                                        <div className="jewelry-link">
                                            Übersicht
                                        </div>
                                    </CustomLink>
                                </div>

                            </div>

                        </section>
                        <section className="content-section-jewelry">
                            <div className="jewelry-number">03.</div>
                            <div className="jewelry-content">
                                <div className="image-block">
                                    <img src={Jewelry_picture4} alt="Detailreiche Ohrringe" id="fullimg" />
                                </div>
                                <div className="text-block">
                                    <h2>Ohrringe</h2>
                                    <p> Jedes Detail wird von Hand ausgewählt, sorgfältig durchdacht und
                                        zum Leben erweckt.</p>
                                    <CustomLink to="/jewelry?type=Ohrringe" showOverlay={showOverlay} >
                                        <div className="jewelry-link">
                                            Übersicht
                                        </div>
                                    </CustomLink>
                                </div>

                            </div>

                        </section>
                        <section className="content-section-jewelry">
                            <div className="jewelry-number">04.</div>
                            <div className="jewelry-content">
                                <div className="image-block">
                                    <img src={Jewelry_picture5} alt="Identitätsstücke Armbänder" id="fullimg" />
                                </div>
                                <div className="text-block">
                                    <h2>Armbänder</h2>
                                    <p> Konzipiert als Identitätsstück und einzigartig in der Verarbeitung.</p>
                                    <CustomLink to="/jewelry?type=Armbänder" showOverlay={showOverlay} >
                                        <div className="jewelry-link">
                                            Übersicht
                                        </div>
                                    </CustomLink>
                                </div>

                            </div>

                        </section>
                    </section>
                </div>
            </Parallax>
        </div>

    );
};

export default JewerlyOverview;
