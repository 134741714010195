import React from "react";
import { useLocation } from "react-router-dom";
import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import CustomLink from "../assets/Custom_Elements/CustomLink";

const Footer = ({ showOverlay }) => {
  const location = useLocation();
  const paddingTop = (location.pathname === "/contact") || (location.pathname === "/jewelry") || (location.pathname === "/impressum") || (location.pathname === "/privacy") ? "15vh" : "0";
  return (
    <footer className="footer" style={{ paddingTop }}>
      <div className="footer-empty"></div>
      <div className="footer-wrapper">
        <div className="footer-top"></div>
        <div className="footer-content">
          <div className="contact-info">
            <div className="info-box">
              <p className="email-title"><strong>EMAIL & TELEFON</strong></p>
              <div className="content">
                <p><a href="mailto:juwelenschmiede@kerecz.at">juwelenschmiede@kerecz.at</a></p>
                <p><a href="tel:+436642533419">+43 664 2533419</a></p>
              </div>
            </div>
          </div>
          <div className="address">
            <div className="info-box">
              <p className="address-title"><strong>ADRESSE</strong></p>
              <div className="content">
                <a id="linkfooter"
                  href="https://maps.app.goo.gl/C8q9oxYMGVZTFFDi9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Studenzen 146a</p>
                  <p>8322 Kirchberg an der Raab, Österreich</p>
                </a>
              </div>

            </div>
          </div>
          <div className="social-links">
            <div className="info-box">
              <CustomLink to={"/contact"} showOverlay={showOverlay}>
                <p className="kontakt-title"><strong>KONTAKT</strong></p>
              </CustomLink>
              <div className="content">
                <a href="https://www.facebook.com/people/Anton-Kerecz/61567885661034/" target="_blank" rel="noopener noreferrer" aria-label="Facebook link">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="https://www.instagram.com/juwelenschmiede_kerecz/" target="_blank" rel="noopener noreferrer" aria-label="Instagram link">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>Alle Rechte vorbehalten © Anton Kerecz 2024</p>
          <div className="footer-bottom-div">
            <CustomLink to="/privacy" showOverlay={showOverlay}>Datenschutz</CustomLink> <CustomLink to="/impressum" showOverlay={showOverlay}>Impressum</CustomLink>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
