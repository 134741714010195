import React, { useState, useEffect } from 'react';
import './ProductPage.css';

const ProductPage = React.forwardRef(({ id, products, isHidden }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Find the product based on the ID
  const product = products.find((p) => p.id === parseInt(id, 10));

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('no-scroll'); // Disable scrolling
    } else {
      document.body.classList.remove('no-scroll'); // Enable scrolling
    }

    return () => {
      document.body.classList.remove('no-scroll'); // Clean up when component unmounts or isModalOpen changes
    };
  }, [isModalOpen]);

  if (!product) return <div ref={ref} className={`product-page ${isHidden ? 'hidden' : ''}`}></div>;

  // Function to handle opening the modal
  const openModal = () => setIsModalOpen(true);

  // Function to handle closing the modal
  const closeModal = () => setIsModalOpen(false);

  return (
    <div ref={ref} className={`product-page ${isHidden ? 'hidden' : ''}`}>
      <img 
        src={product.image_HQ} 
        alt={product.name} 
        className="product-img" 
        onClick={openModal} 
        style={{ cursor: 'pointer' }} 
      />
      <div className="description-box">
        <h1>{product.name}</h1>
        <p><b>Beschreibung und Details</b></p>
        <p>{product.description}</p>
      </div>
      
      {/* Modal Component */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content-image" onClick={closeModal}>
            <img src={product.image_HQ} alt={product.name} className="modal-img" />
          </div>
        </div>
      )}
    </div>
  );
});

export default ProductPage;
