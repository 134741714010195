// src/subsites/DownwardArrow.js
import React from 'react';
import './DownwardArrow.css';

const DownwardArrow = ({ isVisible, opacity }) => {
  return (
    isVisible && (
      <div className="arrow-container" style={{ opacity: opacity }}>
        <div className="dots_arrow">
          <div className="dot_arrow"></div>
          <div className="dot_arrow"></div>
          <div className="dot_arrow"></div>
          <div className="dot_arrow"></div>
          <div className="dot_arrow"></div>
        </div>
        <div className="arrow">
          <div className="line left"></div>
          <div className="line right"></div>
        </div>
      </div>
    )
  );
};

export default DownwardArrow;
