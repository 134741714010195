import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './MenubarSmall.css';
import CustomLink from './CustomLink';

const MenubarSmall = ({ isVisible, isAtTop, showOverlay, toggleDropdown, isDropdownVisible }) => {
    const location = useLocation();

    const shouldDisplayMenubar = (isVisible && (location.pathname !== '/trend-detail' || window.innerWidth <= 900 )) || location.pathname === '/impressum' || location.pathname === '/privacy' || location.pathname === '/contact';

    return (
        <div className={`menubar-small ${isAtTop && !(location.pathname === '/impressum' || location.pathname === '/privacy' || location.pathname === '/contact') ? 'transparent' : ''} ${!shouldDisplayMenubar ? 'transparent' : ''}`}>
            <div className={`menubar-small-container ${shouldDisplayMenubar || isDropdownVisible ? '' : 'hidden'}`}>
                {/* Hamburger Icon */}
                <div className="hamburger-icon" onClick={toggleDropdown}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>

            {/* The dropdown menu visibility depends on isDropdownVisible */}
            <div className={`dropdown-menu-small ${isDropdownVisible ? 'show' : ''}`}>
                <div className='navigator-small'>
                <CustomLink to="/artist" showOverlay={showOverlay} >Artist</CustomLink>
                <CustomLink to="/jewelryoverview" showOverlay={showOverlay} >Jewelry</CustomLink>
                <CustomLink to="/trend" showOverlay={showOverlay} >Trend</CustomLink>
                <CustomLink to="/atelier" showOverlay={showOverlay} >Atelier</CustomLink>
                <CustomLink to="/factory" showOverlay={showOverlay} >Factory</CustomLink>
       
                </div>
            </div>
        </div>
    );
};

export default MenubarSmall;
