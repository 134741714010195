import React from "react";
import { Parallax } from 'react-scroll-parallax';
import "./DesignMainSites.css";
import Atelier_picture0 from '../assets/pictures/Atelier/Atelier0.JPG';
import Atelier_picture1 from '../assets/pictures/Atelier/Atelier1.jpg';
import Atelier_picture2 from '../assets/pictures/Atelier/Atelier2.jpg';
import Atelier_picture3 from '../assets/pictures/Atelier/Atelier3.jpg';
import Atelier_picture4 from '../assets/pictures/Atelier/Atelier4.jpg';
import Atelier_picture5 from '../assets/pictures/Atelier/Atelier5.jpg';
import { easeInOut } from "framer-motion";
import { Helmet } from "react-helmet";


const Atelier = ({ parallaxSpeed }) => {
  return (

    <div className="content-main">

      <Helmet>
        <title>Atelier</title>
        <meta name="description" content="Standort ist alles. Vor allem,
                  wenn es darum geht Besonderes
                  zu schaffen." />
      </Helmet>
      <Parallax className="parallax-header" speed={0} easing={easeInOut}>
        <img src={Atelier_picture0} className="parallax-image" alt="Das Atelier" />
        <div className="mainsites-header-text atelier">
          <h1>Atelier</h1>
        </div>
      </Parallax>

      <Parallax speed={parallaxSpeed} className="parallax-content" easing={easeInOut}>
        <div className="mainsiteswrapper">
          <div className="mainsites-content-section">
            <section className="content-section vertical-layout">
              <div className="text-block">
                <h2>Eine Quelle von Inspiration, Ruhe und Austausch</h2>
              </div>
              <div className="image-block vertical">
                <img src={Atelier_picture1} alt="Das Atelier" />
              </div>
            </section>


            <section className="content-section">
              <div className="text-block">
                <h2>Eleganz und Stil</h2>
                <p>
                  Standort ist alles. Vor allem,
                  wenn es darum geht Besonderes
                  zu schaffen. So ist das Atelier
                  am persönlichen Kraftplatz des
                  Künstlers erbaut und bietet mit
                  seinem einzigartigen Design
                  eine ausnehmend angenehme,
                  elegante Atmosphäre.
                </p>
              </div>
              <div className="image-block">
                <img src={Atelier_picture2} alt="Eleganz und Stil" />
              </div>
            </section>


            <section className="content-section vertical-layout">
              <div className="image-block vertical">
                <img src={Atelier_picture3} alt="In ruhe gustieren" />
              </div>
              <div className="text-block">
                <h2>In der Ruhe liegt die Kraft

                </h2>
                <p>
                  Treten Sie ein in die Welt der Leidenschaft und Emotionen, wo sich Gold und edle Steine zum Rendez-vous treffen. Bei Wasser, Espresso oder Champagner können Sie hier in aller Ruhe gustieren, probieren und Ihr Herz verlieren.
                </p>
              </div>
            </section>

            <div className="yellowBackground atelier">

              <section className="content-section overflow">
                <div className="text-block">
                  <h2> Unbegrenzte Möglichkeiten
                  </h2>
                  <p>
                    Wenn Sie in das Atelier kommen, bedeutet das nicht, dass Sie ein Standardschmuckstück kaufen möchten. Es bedeutet, dass Sie Ihre Kreativität entdecken und diese in einem Objekt finden möchten.
                  </p>
                  <p>
                    Entdecken Sie das Potential maßgeschneiderter Handwerkskunst und unbegrenzter Möglichkeiten.
                  </p>
                </div>
                <div className="image-block overflow">
                  <img src={Atelier_picture4} alt="Unbegrenzte Möglichkeiten" className="overflow-image" />
                </div>
              </section>
            </div>


            <section className="content-section vertical-layout">
              <div className="image-block vertical">
                <img src={Atelier_picture5} alt="Die Extravaganz des Ateliers" />
              </div>
              <div className="text-block">
                <h2>Extravaganz</h2>
                <p>
                  In der scheinbaren Schlichtheit liegt ein Hauch Extravaganz. Das Geheimnis von Anton
                  Kerecz liegt darin, dass jedes Schmuckstück die individuelle Note der Trägerin bzw. des
                  Trägers unterstreicht.
                </p>
              </div>
            </section>
          </div>
        </div>
      </Parallax>
    </div>

  );
};

export default Atelier;
