import React from 'react';
import "./ProductCard.css"

const ProductCard = ({ product, onClick }) => {
  return (
    <div className="product-card" onClick={onClick}>
      <img src={product.image} alt={product.name} className='product-image' />
    </div>
  );
};

export default ProductCard;
