import React, { useState, useEffect } from 'react';
import './CustomPicture.css';

const CustomPicture = ({imageSrc, handleClick }) => {

  return (
    <div className="custom-picture">
      <div className="image-container" onClick={handleClick}>
        <img src={imageSrc} alt="Video" className="thumbnail" />
        <button className="play-button" onClick={handleClick} aria-label="Play-button">
        </button>
      </div>
    </div>
  );
};

export default CustomPicture;
