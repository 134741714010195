import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import products from '../products/products';
import CategorySelector from '../assets/Custom_Elements/CategorySelector';
import ProductCatalog from '../assets/Custom_Elements/ProductCatalog';
import './Jewelry.css';
import ProductPage from './ProductPage';
import { Helmet } from 'react-helmet';

const Jewelry = () => {
  const [selectedCategory, setSelectedCategory] = useState('Ringe');
  const categories = ['Ringe', 'Halsketten', 'Armbänder', 'Ohrringe'];
  const [id, setId] = useState(0);
  const filteredProducts = products.filter(product => product.category === selectedCategory);
  const [hideProductCatalog, setHideProductCatalog] = useState(false);
  const [hideProductDetails, setHideProductDetails] = useState(true);
  const [transition, setTransition] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const jewelryType = params.get('type');

  const productPageRef = useRef(null);
  const productSizeRef = useRef(null);

  const [productPageHeight, setProductPageHeight] = useState(null);

  const switchProduct = (id) => {
    if (id != null) {
      setTransition(true);
      if (window.innerWidth < 900) {
        productPageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });

      }

      setTimeout(() => {
        setHideProductCatalog(true);
        setId(id);
        setHideProductDetails(false);
      }, 700);

      setTimeout(() => {
        setTransition(false);
      }, 1000);
    }
  };

  const setCategory = (any) => {
    setTransition(true);
    setTimeout(() => {
      setSelectedCategory(any);
      setHideProductDetails(true);
      setHideProductCatalog(false);
      setId(0);
    }, 500);

    setTimeout(() => {
      setTransition(false);
    }, 700);
  };

  useEffect(() => {
    if (jewelryType != null) {
      setSelectedCategory(jewelryType);
    }
  }, []);

  useEffect(() => {
    if (!hideProductDetails && id && productSizeRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          setProductPageHeight(height);
        }
      });

      resizeObserver.observe(productSizeRef.current);

      // Clean up observer on component unmount or if dependencies change
      return () => resizeObserver.disconnect();
    } else {
      setProductPageHeight(null);
    }
  }, [id, hideProductDetails]);



  return (
    <div className="jewelry">

      <Helmet>
        <title>Schmuckauswahl Kerecz</title>
        <meta name="description" content="Schwelgen Sie in der Auswahl. Genießen Sie das Funkeln. Verlieben Sie sich in
                                jedes einzelne Schmuckstück." />
      </Helmet>
      <div className="jewelry-container">
        <div className={`transition-overlay ${transition ? 'show' : ''}`}></div>
        <div className="jewelry-content-container">
          <CategorySelector
            categories={categories}
            selectedCategory={selectedCategory}
            onCategoryChange={setCategory}
          />

          <div className="content-container" ref={productPageRef}>
            <div className={`jewelry-header-text ${transition || id !== 0 ? 'hide' : ''}`}>{selectedCategory}</div>


            {/* Pass dimensions as inline styles or props */}
            <ProductCatalog
              products={filteredProducts}
              isHidden={hideProductCatalog}
              onSelectProduct={switchProduct}
              height={productPageHeight}
            />

            {/* Pass the ref to ProductPage */}
            <ProductPage ref={productSizeRef} id={id} products={products} isHidden={hideProductDetails} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jewelry;
