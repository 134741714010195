import React, { useState } from "react";
import "./ContactFormular.css";
import ContactFormular_picture0 from '../assets/pictures/ContactFormular/ContactFormular0.jpg';
import { Helmet } from "react-helmet";
import CustomLink from "../assets/Custom_Elements/CustomLink";

const ContactFormular = ({ showOverlay }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        privacyConsent: false
    });

    const [formStatus, setFormStatus] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.privacyConsent) {
            setFormStatus('Please agree to the privacy policy.');
            return;
        }

        try {
            const response = await fetch('contact-form-handler.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(formData)
            });

            if (response.ok) {
                setFormStatus('Message sent successfully!');
                setFormData({ name: '', email: '', phone: '', message: '', privacyConsent: false }); // Clear the form
            } else {
                setFormStatus('Failed to send message. Please try again.');
            }
        } catch (error) {
            setFormStatus('An error occurred. Please try again later.');
            console.log(error);
        }
    };

    return (

        <div className="contact-page">

            <Helmet>
                <title>Kontakt</title>
                <meta name="description" content="Ein Schmuckstück, das Sie sich erträumen,
                        eine Frage, die Ihnen auf der Zunge liegt,
                        ein Gedanke, der Ihnen durch den Kopf geht…" />
            </Helmet>
            <div className="contact-content">
                <img className="contact-picture" src={ContactFormular_picture0} alt="Contact" />
                <div className="contact-header">
                    <h2>Kontakt</h2>
                    <h2>Studenzen 146a,</h2>
                    <h2>8322  Kirchberg an der Raab, Österreich</h2>
                </div>
                <div className="contact-text">
                    <p>Ein Schmuckstück, das Sie sich erträumen,
                        eine Frage, die Ihnen auf der Zunge liegt,
                        ein Gedanke, der Ihnen durch den Kopf geht…</p>
                </div>
                <div className="contact-sub-text">
                    <p>Hier haben Sie die Möglichkeit, mir Ihre Nachricht zu schreiben. Wenn Sie mich persönlich sprechen möchten, erreichen Sie mich unter +43 664 2533419.
                    </p>
                </div>

                <div className="contact-form">
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="left-column">
                                <div className="form-group">
                                    <label htmlFor="name">Name*</label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phone">Telefonnummer*</label>
                                    <input
                                        type="tel"
                                        name="phone"
                                        id="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email*</label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="right-column">
                                <div className="form-group right">
                                    <label htmlFor="message">Nachricht*</label>
                                    <textarea
                                        name="message"
                                        id="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group privacy">
                            <label className="privacy-consent">
                                <input
                                    type="checkbox"
                                    name="privacyConsent"
                                    checked={formData.privacyConsent}
                                    onChange={handleChange}
                                    required
                                    className="input-checkbox"
                                />
                                <span>
                                    Durch das Absenden dieses Formulars stimme ich der <CustomLink showOverlay={showOverlay} to={"/privacy"}> Datenschutzrichtlinie </CustomLink> zu.
                                </span>
                                
                            </label>
                            <button type="submit">Senden</button>
                        </div>

                        {formStatus && <p className="form-status">{formStatus}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactFormular;
