import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import './App.css';
import Menubar from './assets/Custom_Elements/Menubar.js';
import Artist from './subsites/Artist.js';
import Atelier from './subsites/Atelier.js';
import Factory from './subsites/Factory.js';
import Jewelry from './subsites/Jewelry.js';
import Trend from './subsites/Trend.js';
import Home from './subsites/Home.js';
import Footer from './subsites/Footer.js';
import Logo from './assets/Custom_Elements/Logo.js';
import Transition from './assets/Custom_Elements/Transition';
import Shadow from './assets/Custom_Elements/Shadow.js';
import JewelryOverview from './subsites/JewelryOverview.js';
import TrendDetail from './subsites/TrendDetail.js';
import ContactFormular from './subsites/ContactFormular.js';
import DataPrivacy from './subsites/DataPrivacy.js';
import Impressum from './subsites/Impressum.js';
import MenubarSmall from './assets/Custom_Elements/MenubarSmall.js';
import { use } from 'framer-motion/client';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import VideoOverlay from './assets/Custom_Elements/VideoOverlay.js';


function App() {
  const [isMenubarVisible, setIsMenubarVisible] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [smallMenuVisible, setSmallMenuVisible] = useState(true);
  const [parallaxSpeed, setParallaxSpeed] = useState(20);
  const showedMenuMouse = useRef(false);
  const [showVideoOverlay, setShowVideoOverlay] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [shadowRefVisible, setShadowRefVisible] = useState(false);
  const [subParallaxSpeed, setSubParallaxSpeed] = useState(20);
  const showOverlay = useRef(null);
  const hideOverlay = useRef(null);
  const shadowRef = useRef(null);

  useEffect(() => {
    const updateParallaxSpeed = () => {
      if (window.innerWidth <= 900) {
        setParallaxSpeed(10); 
        setSubParallaxSpeed(0);
      } else {
        setParallaxSpeed(20); 
        setSubParallaxSpeed(20);
      }

      if (window.innerHeight <= 500) {
        setParallaxSpeed(0);
        setSubParallaxSpeed(0);
      }
    };

    updateParallaxSpeed();
    window.addEventListener('resize', updateParallaxSpeed);
    return () => window.removeEventListener('resize', updateParallaxSpeed);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > lastScrollPosition && !showedMenuMouse.current) {
        setIsMenubarVisible(false); // Scrolling down - hide menubar
      } else if (!showedMenuMouse.current) {
        setIsMenubarVisible(true); // Scrolling up - show menubar
      }

      setIsAtTop(scrollPosition <= (window.innerHeight * 0.05));

      if (scrollPosition > lastScrollPosition) {
        setSmallMenuVisible(false);
      } else {
        setSmallMenuVisible(true);
      }

      if (isAtTop) {
        setSmallMenuVisible(true);
      }

      if (isAtTop) {
        setIsMenubarVisible(true);
      }

      setLastScrollPosition(scrollPosition);
    };

    const handleMouseMove = (e) => {
      const yPositionPercentage = (e.clientY / window.innerHeight) * 100;

      if (yPositionPercentage < 20 && !isAtTop) {
        setIsMenubarVisible(true);
        showedMenuMouse.current = true;
      } else if (yPositionPercentage > 30 && showedMenuMouse.current && !isAtTop) {
        showedMenuMouse.current = false;
        setIsMenubarVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [lastScrollPosition, isAtTop]);





  useEffect(() => {
    const timer = setTimeout(() => {
      if (hideOverlay.current) {
        hideOverlay.current();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showVideoOverlay || isDropdownVisible) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isDropdownVisible, showVideoOverlay]);


  const toogleVideoOverlay = () => {
    setShowVideoOverlay(!showVideoOverlay);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };


  useEffect(() => {
    const handlePageShow = (event) => {
      if (event.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);


  return (
    <div className='App'>
      <ParallaxProvider>
        <Router>
          <MenubarSmall
            isVisible={smallMenuVisible}
            isAtTop={isAtTop}
            showOverlay={showOverlay}
            toggleDropdown={toggleDropdown}
            isDropdownVisible={isDropdownVisible}
          />
          <Menubar
            isVisible={isMenubarVisible}
            isAtTop={isAtTop}
            showOverlay={showOverlay}
            shadowRef={shadowRef}
            setShadowRef={setShadowRefVisible}
          />
          <Logo
            isMenubarVisible={isMenubarVisible}
            showOverlay={showOverlay}
            isAtTop={isAtTop}
            isDropdownVisible={isDropdownVisible}
            isSmallMenubarVisible={smallMenuVisible}
            shadowRefVisible={shadowRefVisible}
          />
          <Transition showOverlay={showOverlay} hideOverlay={hideOverlay} />
          <Shadow shadowRef={shadowRef} />
          <VideoOverlay
            showOverlay={showVideoOverlay}
            toogleOverlay={toogleVideoOverlay}
          />
          <Routes>
            <Route path="/" element={<Home showOverlay={showOverlay} parallaxSpeed={parallaxSpeed} toogleVideoOverlay={toogleVideoOverlay} />} />
            <Route path="/artist" element={<Artist parallaxSpeed={subParallaxSpeed} toogleVideoOverlay={toogleVideoOverlay} />} />
            <Route path="/atelier" element={<Atelier parallaxSpeed={subParallaxSpeed} />} />
            <Route path="/factory" element={<Factory parallaxSpeed={subParallaxSpeed} />} />
            <Route path="/jewelryoverview" element={<JewelryOverview showOverlay={showOverlay} parallaxSpeed={subParallaxSpeed} />} />
            <Route path="/jewelry" element={<Jewelry parallaxSpeed={subParallaxSpeed} />} />
            <Route path="/trend" element={<Trend showOverlay={showOverlay} hideOverlay={hideOverlay} parallaxSpeed={subParallaxSpeed} />} />
            <Route path="/trend-detail" element={<TrendDetail showOverlay={showOverlay} />} />
            <Route path="/contact" element={<ContactFormular showOverlay={showOverlay} />} />
            <Route path="/privacy" element={<DataPrivacy />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer showOverlay={showOverlay} />
        </Router>
      </ParallaxProvider>
    </div>
  );
}

export default App;
