import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logoBright from '../pictures/logo_white.png';
import logoDark from '../pictures/logo.png';
import CustomLink from './CustomLink';
import './Logo.css';

const Logo = ({ isMenubarVisible, showOverlay, isDropdownVisible, isSmallMenubarVisible, shadowRefVisible}) => {
    const [isScrollingDown, setIsScrollingDown] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [forceLogoDark, setForceLogoDark] = useState(false);
    const location = useLocation();
    const [forcebright, setForceBright] = useState(false);
    const [logovisible, setLogoVisible] = useState(false);



    // Handle scrolling and update scrolling state
    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            setIsScrollingDown(true); // User is scrolling down
        } else {
            setIsScrollingDown(false); // User is scrolling up
        }
        setLastScrollY(window.scrollY); // Update the last scroll position




        if ((location.pathname === '/jewelry' || location.pathname === '/trend-detail') && window.scrollY === 0) {
            setForceLogoDark(true); // Force dark logo at the top of the jewelry page
        } else {
            setForceLogoDark(false); // Disable forced dark logo otherwise
        }

        // Determine if the logo should be visible based on the current path
        setLogoVisible((location.pathname !== '/trend-detail') && (900 < window.innerWidth) || ((900 > window.innerWidth) && isSmallMenubarVisible) || location.pathname == "/contact" || location.pathname == "/privacy" || location.pathname == "/impressum");

    };

    // Handle scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY, location.pathname]);

    // Check on initial load if the logo should be forced to dark
    useEffect(() => {
        if ((location.pathname === '/jewelry' || location.pathname === '/trend-detail') && window.scrollY === 0) {
            setForceLogoDark(true);
        } else {
            setForceLogoDark(false);
        }

        setForceBright(location.pathname === "/" || location.pathname === '/impressum' || location.pathname === '/privacy' || location.pathname === '/contact' || isDropdownVisible);
        // Determine if the logo should be visible based on the current path
        setLogoVisible((location.pathname !== '/trend-detail') && (900 < window.innerWidth) || ((900 > window.innerWidth) && isSmallMenubarVisible) || location.pathname == "/contact" || location.pathname == "/privacy" || location.pathname == "/impressum");

    }, [location.pathname]);




    useEffect(() => {

        setForceBright(location.pathname === "/" || location.pathname === '/impressum' || location.pathname === '/privacy' || location.pathname === '/contact' || isDropdownVisible || shadowRefVisible);
        // Determine if the logo should be visible based on the current path
        setLogoVisible((location.pathname !== '/trend-detail') && (900 < window.innerWidth) || ((900 > window.innerWidth) && isSmallMenubarVisible) || location.pathname == "/contact" || location.pathname == "/privacy" || location.pathname == "/impressum");
        
    }, [isDropdownVisible, shadowRefVisible]);




    return (

        <div className={`logo-container ${logovisible ? "" : "hidden"}`}>
            <CustomLink to="/" showOverlay={showOverlay}>
                {/* Bright logo should only be visible when menubar is visible and the user is not scrolling down */}
                <img
                    src={logoBright}
                    alt="Bright Logo"
                    className={`logo ${((isMenubarVisible || !isScrollingDown) && !forceLogoDark) | forcebright ? 'visible' : 'hidden'}`}
                />
                {/* Dark logo should only be visible when the user is scrolling down or when forced */}
                <img
                    src={logoDark}
                    alt="Dark Logo"
                    className={`logo ${((isScrollingDown && !isMenubarVisible) || forceLogoDark || !isMenubarVisible) && !forcebright && !shadowRefVisible ? 'visible' : 'hidden'}`}
                />
            </CustomLink>
        </div>

    );
};

export default Logo;
