import React from "react";
import { Parallax } from 'react-scroll-parallax';
import "./DesignMainSites.css";
import Artist_picture0 from '../assets/pictures/Artist/Artist_0.JPG';
import Artist_picture1 from '../assets/pictures/Artist/Artist_1.jpg';
import Artist_picture2 from '../assets/pictures/Artist/Artist_2.jpg';
import Artist_picture3 from '../assets/pictures/Artist/Artist_3.jpg';
import Artist_picture4 from '../assets/pictures/Artist/Artist_4.jpg';
import Artist_picture5 from '../assets/pictures/Artist/Artist_5.jpg';
import { easeInOut } from "framer-motion";


import { Helmet } from "react-helmet";


const Artist = ({ parallaxSpeed, toogleVideoOverlay }) => {
  return (

    <div className="content-main">
      <Helmet>
        <title>Artist</title>
        <meta name="description" content="Aus dem einst jüngsten Goldschmiedemeister Österreichs entwickelte sich ein Künstler, der mit einer zielsicheren Leichtigkeit die Brücke zwischen Klarheit und Extravaganz schafft." />
      </Helmet>
      <Parallax className="parallax-header" speed={0} easing={easeInOut} >
        <img src={Artist_picture0} alt="Der Künstler Anton Kerecz" className="parallax-image" />
        <div className="mainsites-header-text">
          <h1>Artist</h1>
        </div>
      </Parallax>
      <Parallax speed={parallaxSpeed} className="parallax-content" easing={easeInOut}>
        <div className="mainsiteswrapper">
          <section className="mainsites-content-section">
            <section className="content-section vertical-layout">
              <div className="text-block">
                <h2>Leidenschaft und Kunst</h2>
              </div>

              <div className="image-block vertical">
                <img src={Artist_picture1} alt="Inspiration der Freiheit" />
              </div>
            </section>

            <section className="content-section">
              <div className="text-block">
                <h2> Klarheit und Extravaganz
                </h2>
                <p>
                Aus dem einst jüngsten Goldschmiedemeister Österreichs entwickelte sich ein Künstler, der mit einer zielsicheren Leichtigkeit die Brücke zwischen Klarheit und Extravaganz schafft.
                </p>
                <p>
                Um diesen Ansprüchen nachzukommen, arbeitet Anton Kerecz ausschließlich mit 18 Karat Gold in all seinen Nuancen - Weißgold, Gelbgold, Rotgold - und edlen, handverlesenen Steinen.
                </p>
              </div>
              <div className="image-block small">
                <img src={Artist_picture2} alt="Anton Kerecz" />
              </div>
            </section>

          
            <section className="content-section vertical-layout">
              <div className="image-block vertical">
                <img src={Artist_picture3} alt="Schaffen in der Factory" />
              </div>
              <div className="text-block">
                <h2> Inspiration und Motivation
                </h2>
                <p>
                Die pure Schönheit des Lebens und die kulturellen Berührungen auf Reisen sind Inspiration und Motivation für die Kreationen des Schmuckkünstlers. Jede Berührung, die durch eine unerwartete Begegnung hervorgerufen wird, kann zu einem Absatz in der Geschichte des Künstlers werden.
                </p>
              </div>
            </section>

            <div className="yellowBackground">
            
              <section className="content-section overflow">
                <div className="text-block">
                  <h2> Charme und Persönlichkeit
                  </h2>
                  <p>
                    Ein Stein. Eine Vision. Eine Skizze. Das sind die Anfänge
                    eines jeden großen Kunstwerks. Mit Bedacht und
                    Perfektion erweckt Anton Kerecz Gold zum Leben.
                  </p>
                  <p>
                  Hochwertiger Schmuck verleiht Charme, der nicht nur den Lebensstil widerspiegelt, sondern auch ein Ausdruck der eigenen Persönlichkeit ist.
                  </p>
                </div>
                <div className="image-block overflow">
                  <img src={Artist_picture4} alt="Design des Künstlers" className="overflow-image" />
                </div>
              </section>
            </div>

            {/* Bottom Content Section */}
            <section className="content-section vertical-layout">
              <div className="image-block vertical">
                <img src={Artist_picture5} alt="Klares Statement" />
              </div>
              <div className="text-block">
                <h2> Ein klares Statement
                </h2>
                <p>
                Jedes Schmuckstück erzählt eine Geschichte. Jede Geschichte beginnt mit einem Konzept. Inspiriert von der Kunst kreiert Anton Kerecz Schmuck für Persönlichkeiten, die keine Angst haben, ein Statement abzugeben.
                </p>
              </div>
            </section>
          </section>
        </div>
      </Parallax>
    </div>

  );
};

export default Artist;
