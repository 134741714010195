import React, { useState, useEffect, useRef } from 'react';
import { Parallax } from 'react-scroll-parallax';
import backgroundVideoDesktop from '../assets/videos/Kerecz_Final_4K.mp4';
import Artist_picture from '../assets/pictures/Artist.jpg';
import Jewelry_picture from '../assets/pictures/Jewelry.jpg';
import Atelier_picture from '../assets/pictures/Atelier.jpg';
import Trend_picture from '../assets/pictures/Trend.jpg';
import Factory_picture from '../assets/pictures/Factory.jpg';
import DownwardArrow from '../assets/Custom_Elements/DownwardArrow.js';
import VideoPicture from '../assets/pictures/VideoPicture.jpg';
import './Home.css';
import CustomLink from '../assets/Custom_Elements/CustomLink';
import CustomPicture from '../assets/Custom_Elements/CustomPicture.js';
import { easeInOut } from 'framer-motion';
import { motion } from 'framer-motion';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Helmet } from 'react-helmet';




const Home = ({ showOverlay, parallaxSpeed, toogleVideoOverlay }) => {
  const [activeSection, setActiveSection] = useState(0);
  const [arrowOpacity, setArrowOpacity] = useState(1);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isMuted, setIsMuted] = useState(true); // State for muting the video
  const videoRef = useRef(null);
  const [backgroundSpeed, setBackgroundSpeed] = useState(-30);
  const [videoSource, setVideoSource] = useState(backgroundVideoDesktop);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };


  useEffect(() => {
    if (videoRef.current && !videoRef.current.paused) {
      videoRef.current.play();
    }
  }, [isMuted]);


  useEffect(() => {




    if (videoRef.current && !videoRef.current.paused) {
      videoRef.current.play();
    }


    if (window.innerWidth <= 900) {
      setBackgroundSpeed(-20); 
    } else {
      setBackgroundSpeed(-30);
    }
  }, []);


  useEffect(() => {
    const sections = document.querySelectorAll('.spaced-text');
    const handleScroll = () => {
      let closestSectionIndex = -1;
      let closestDistance = Number.MAX_VALUE;
      const offset = window.innerHeight * 0.5;

      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        const middle = rect.top + rect.height / 2;
        const distance = Math.abs(middle - offset);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestSectionIndex = index;
        }
      });

      if (closestSectionIndex !== -1) {
        setActiveSection(closestSectionIndex);
      }

      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const maxScrollHeight = document.documentElement.scrollHeight - windowHeight;
      const scrollProgress = Math.min(scrollY / maxScrollHeight, 1);

      const arrowFadeStart = 0;
      const arrowFadeEnd = 0.1;
      if (scrollProgress >= arrowFadeStart) {
        const opacity = Math.max(0, 1 - (scrollProgress - arrowFadeStart) / (arrowFadeEnd - arrowFadeStart));
        setArrowOpacity(opacity);
      } else {
        setArrowOpacity(1);
      }
    };

    const debouncedHandleScroll = () => {
      window.requestAnimationFrame(handleScroll);
    };

    videoRef.current.play(); // Start the video when unmuted

    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };



  }, []);

  const scrollToSection = (index) => {
    if (isScrolling) return; // Prevent multiple clicks during scroll

    setIsScrolling(true);
    const section = document.getElementById(`section-${index}`);
    if (section) {
      const scrollInterval = setInterval(() => {
        const rect = section.getBoundingClientRect();
        const middle = rect.top + rect.height / 2;
        const targetPosition = window.innerHeight / 2;
        const distance = middle - targetPosition;

        if (Math.abs(distance) < 30) {
          clearInterval(scrollInterval);
          setIsScrolling(false); // Allow further clicks once scrolling is complete
        } else {
          window.scrollBy({ top: distance / 30, behavior: 'auto' });
        }
      }, 16); // Roughly 60fps
    }
  };

  const scrollToTop = () => {
    if (isScrolling) return; // Prevent multiple clicks during scroll

    setIsScrolling(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => setIsScrolling(false), 1000); // Re-enable scrolling after animation
  };

  return (
    <div className="home">
      <Helmet>
        <title>Juwelenschmiede Kerecz Studenzen </title>
        <meta name="description" content="Jede Geschichte beginnt mit einem Konzept - Anton Kerecz" />
      </Helmet>
      <div className="scroll-indicator">
        {[...Array(7)].map((_, index) => (
          <div
            key={index}
            className={`dot ${activeSection === index ? 'active' : ''}`}
            onClick={() => (index === 0 ? scrollToTop() : scrollToSection(index))}
          ></div>
        ))}
      </div>
      <div className="mute-button" onClick={toggleMute}>
        <i className={`${isMuted ? "fa-solid fa-volume-off" : "fa-solid fa-volume-high"}`}></i>
      </div>

      <div className="main-container">
        <Parallax speed={backgroundSpeed} className="background-container">

          <div className="background-container-overlay"></div>
          <DownwardArrow isVisible={true} opacity={arrowOpacity} />
          <div className="video-container">
            <video className="background-video" autoPlay loop muted={isMuted} ref={videoRef} playsInline>
              <source src={videoSource} preload="auto" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="video-mask"></div>
          </div>
        </Parallax>

        <Parallax speed={parallaxSpeed} easing={'easeOutQuad'}>
          <div className="home-scrollable-content">
            <div className="spaced-text" id="section-0">
              <div className="title-text title">
                <h1>Jede Geschichte beginnt mit einem Konzept</h1>
                <p>Anton Kerecz</p>

              </div>
            </div>
            {[
              {
                title: "Leidenschaft und Kunst", paragraph: "Video", content: (
                  <CustomPicture
                    imageSrc={VideoPicture}
                    handleClick={toogleVideoOverlay}
                  />

                )
              },
              { title: "Ein klares Statement", paragraph: "Artist", image: Artist_picture, path: "/artist" },
              { title: "Wahre Liebe erwartet Sie", paragraph: "Jewelry", image: Jewelry_picture, path: "/jewelryoverview" },
              { title: "Zeitlose Meisterwerke", paragraph: "Trend", image: Trend_picture, path: "/trend" },
              { title: "Eine Quelle von Inspiration, Ruhe und Austausch", paragraph: "Atelier", image: Atelier_picture, path: "/atelier" },
              { title: "Träume werden Realität", paragraph: "Factory", image: Factory_picture, path: "/factory" }
            ].map(({ title, image, content, path, paragraph }, index) => (
              <div className="spaced-text" id={`section-${index + 1}`} key={index}>
                <div className="title-text">
                  {path ? (
                    <CustomLink to={path} showOverlay={showOverlay}>
                      <h2>{title}</h2>
                    </CustomLink>
                  ) : (
                    <h2>{title}</h2>
                  )}
                  <p>{paragraph}</p>
                </div>
                <div className="extra-wrapper">
                  <div className="image-wrapper">
                    {image && (
                      <CustomLink to={path} showOverlay={showOverlay}>
                        <img src={image} loading="lazy" alt={title} className="image-container" />
                      </CustomLink>
                    )}
                    {content}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Parallax>
      </div>
    </div>
  );
};

export default Home;
