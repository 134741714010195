import React, { useEffect } from "react";
import Trend_picture0 from '../assets/pictures/Trend/Trend0.JPG';
import Trend_picture1 from '../assets/pictures/Trend/Trend1.jpg';
import Trend_picture2 from '../assets/pictures/Trend/Trend2.jpg';
import Trend_picture3 from '../assets/pictures/Trend/Trend3.jpg';
import Trend_picture4 from '../assets/pictures/Trend/Trend4.jpg';
import CustomLink from "../assets/Custom_Elements/CustomLink";
import { Parallax } from 'react-scroll-parallax';
import { easeInOut } from "framer-motion";
import { Helmet } from "react-helmet";



const Trend = ({ showOverlay, hideOverlay, parallaxSpeed }) => {


  useEffect(() => {


    const timer = setTimeout(() => {
      if (hideOverlay.current) {
        hideOverlay.current();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);


  return (
    <div className="trend">
      <Helmet>
        <title>Trends</title>
        <meta name="description" content="Von handgezeichneten Skizzen, Wachsschnitzereien, Metallbearbeitung bis hin zu
                    wunderschönem Glitzer – jede Entstehungsgeschichte eines zeitlosen Meisterwerks
                    ist voller humanistischer Gefühle und handwerklicher Schmuckkunst."/>
      </Helmet>
      <div className="content-main">
        <Parallax className="parallax-header" speed={0} easing={easeInOut}>

          <img src={Trend_picture0} className="parallax-image" alt="Einzigartige Sammelstücke" />
          <div className="mainsites-header-text trend">
            <h1>Trend</h1>
          </div>
        </Parallax>

        <Parallax speed={parallaxSpeed} className="parallax-content" easing={easeInOut}>
          <div className="mainsiteswrapper">

            <section className="mainsites-content-section">
              <div className="content-section">
                <div className="text-block-trend-header">
                  <h1>Zeitlose Meisterwerke</h1>
                  <p>
                    Von handgezeichneten Skizzen, Wachsschnitzereien, Metallbearbeitung bis hin zu
                    wunderschönem Glitzer – jede Entstehungsgeschichte eines zeitlosen Meisterwerks
                    ist voller humanistischer Gefühle und handwerklicher Schmuckkunst.
                  </p>
                  <p>
                    Wenn man der Schönheit begegnet, ist der Moment die Ewigkeit.
                  </p>
                </div>
              </div>
              <div className="content-section">
                <CustomLink to="/trend-detail?id=1" showOverlay={showOverlay}>
                  <h1>2023</h1>
                </CustomLink>
                <div className="trend-image-container">
                  <CustomLink to="/trend-detail?id=1" showOverlay={showOverlay}>
                    <div className="image-block trend">
                      <img src={Trend_picture1} alt="Bezaubernder Glantz" />
                    </div>
                  </CustomLink>
                  <div className="image-subtitles-trend">
                    <div className="text-line">
                      <p>2023 / 09</p>
                      <div className="line-container">
                        <div className="line"></div>
                      </div>

                    </div>

                    <p> Bezaubernder Glanz für jeden Moment – Ohrstecker</p>
                  </div>
                </div>
              </div>
              <div className="content-section">
                <CustomLink to="/trend-detail?id=2" showOverlay={showOverlay}>
                  <h1>2021</h1>
                </CustomLink>
                <div className="trend-image-container">
                  <CustomLink to="/trend-detail?id=2" showOverlay={showOverlay}>
                    <div className="image-block trend">
                      <img src={Trend_picture2} alt="Prachtvoll und majestätischer Hirschkäfer" />
                    </div>
                  </CustomLink>
                  <div className="image-subtitles-trend">
                    <div className="text-line">
                      <p>2021 / 04</p>
                      <div className="line-container">
                        <div className="line"></div>
                      </div>

                    </div>
                    <p> Prachtvoll und majestätischer Hirschkäfer – Brosche</p>
                  </div>
                </div>
              </div>
              <div className="content-section">
                <CustomLink to="/trend-detail?id=3" showOverlay={showOverlay}>
                  <h1>2018</h1>
                </CustomLink>
                <div className="trend-image-container">
                  <CustomLink to="/trend-detail?id=3" showOverlay={showOverlay}>
                    <div className="image-block trend">
                      <img src={Trend_picture3} alt="Funkelnde Blume" />
                    </div>
                  </CustomLink>
                  <div className="image-subtitles-trend">
                    <div className="text-line">
                      <p>2018 / 11</p>
                      <div className="line-container">
                        <div className="line"></div>
                      </div>

                    </div>
                    <p> Funkelnde Blume im Sonnenlicht – Ring</p>
                  </div>
                </div>
              </div>
              <div className="content-section">
                <CustomLink to="/trend-detail?id=4" showOverlay={showOverlay}>
                  <h1>2017</h1>
                </CustomLink>
                <div className="trend-image-container">
                  <CustomLink to="/trend-detail?id=4" showOverlay={showOverlay}>
                    <div className="image-block trend">
                      <img src={Trend_picture4} alt="Verlorenes zum Leben" />
                    </div>
                  </CustomLink>
                  <div className="image-subtitles-trend">
                    <div className="text-line">
                      <p>2017 / 03</p>
                      <div className="line-container">
                        <div className="line"></div>
                      </div>

                    </div>
                    <p>Verlorenes zum Leben erwecken – Ohrclips</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Parallax>
      </div>

    </div>
  );
};

export default Trend;
