import React, { useState } from 'react';
import './TrendDetailButton.css';

const TrendDetailButton = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`TrendDetail-button-container ${isHovered ? 'hovered' : ''}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`TrendDetail-button ${isHovered ? 'hovered' : ''}`}>
        <div className="TrendDetail-arrow">
          <div className="TrendDetail-arrow-line"></div>
          <div className="TrendDetail-arrow-head"></div>
        </div>
      </div>
      <div className={`TrendDetail-button-white ${isHovered ? 'hovered' : ''}`}>
        <div className="TrendDetail-arrow-white">
          <div className="TrendDetail-arrow-line-white"></div>
          <div className="TrendDetail-arrow-head-white"></div>
        </div>
      </div>


      <div className={`TrendDetail-overlay ${isHovered ? 'hovered' : ''}`}></div>
    </div>
  );
};

export default TrendDetailButton;
