import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Menubar.css';
import CustomLink from './CustomLink';

const Menubar = ({ isVisible, isAtTop, showOverlay, shadowRef, setShadowRef}) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const isActive = (path) => location.pathname === path;

  // Get current path from React Router
  const location = useLocation();

  // Set isVisible to false if the current path is '/trend-detail'
  const shouldDisplayMenubar = (isVisible && location.pathname !== '/trend-detail') || location.pathname === '/impressum' || location.pathname === '/privacy' || location.pathname === '/contact';

  // Function to handle showing the dropdown
  const showDropdown = () => {
    setDropdownVisible(true);
    if (shadowRef && typeof shadowRef.show === 'function') {
      shadowRef.show();
      setShadowRef(true);
    }
  };

  // Function to handle hiding the dropdown
  const hideDropdown = () => {
    setDropdownVisible(false);
    if (shadowRef && typeof shadowRef.hide === 'function') {
      shadowRef.hide();
      setShadowRef(false);
    }
  };
  
  useEffect(() => {
    if (location.pathname === ('/trend-detail')) {
      document.getElementById('menu').style.pointerEvents = 'none';
    }
    else{
      document.getElementById('menu').style.pointerEvents = 'all';
    }
  }, [location.pathname]); 


  return (
    <div id='menu' className={`menubar ${isAtTop && !(location.pathname === '/impressum' || location.pathname === '/privacy' || location.pathname === '/contact') ? 'transparent' : ''} ${!shouldDisplayMenubar ? 'transparent' : ''}`}>
      <div className={`menubar-container ${isDropdownVisible ? 'hovered' : ''} ${!shouldDisplayMenubar ? 'hidden' : ''} ${isAtTop ? 'transparent' : ''}`}>
        <nav className='menubar-nav'>
          <CustomLink to="/artist" className={isActive('/artist') ? 'active' : ''} showOverlay={showOverlay} onMouseEnter={hideDropdown}>Artist</CustomLink>
          <CustomLink to="/jewelryoverview" className={`${(isActive('/jewelry')) && isAtTop && !isDropdownVisible  ? 'active_j' : ''} ${isActive('/jewelryoverview') ? 'active' : '' } ${(isDropdownVisible || shouldDisplayMenubar) && (isActive('/jewelry')) ? 'active' : ''}`} showOverlay={showOverlay} onMouseEnter={showDropdown}>Jewelry</CustomLink>
          <CustomLink to="/trend" className={isActive('/trend') ? 'active' : ''} showOverlay={showOverlay} onMouseEnter={hideDropdown}>Trend</CustomLink>
          <CustomLink to="/atelier" className={isActive('/atelier') ? 'active' : ''} showOverlay={showOverlay} onMouseEnter={hideDropdown}>Atelier</CustomLink>
          <CustomLink to="/factory" className={isActive('/factory') ? 'active' : ''} showOverlay={showOverlay} onMouseEnter={hideDropdown}>Factory</CustomLink>
        </nav>
        {isDropdownVisible && (
          <div className="dropdown-menu" onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
            <CustomLink to="/jewelry?type=Ringe" className={isActive('/jewelry?type=Ringe') ? 'active' : ''} showOverlay={showOverlay}>
              <span className="dropdown-index">01</span> Ringe
            </CustomLink>
            <CustomLink to="/jewelry?type=Halsketten" className={isActive('/jewelry?type=Halsketten') ? 'active' : ''} showOverlay={showOverlay}>
              <span className="dropdown-index">02</span> Halsketten
            </CustomLink>
            <CustomLink to="/jewelry?type=Ohrringe" className={isActive('/jewelry?type=Ohrringe') ? 'active' : ''} showOverlay={showOverlay}>
              <span className="dropdown-index">03</span> Ohrringe
            </CustomLink>
            <CustomLink to="/jewelry?type=Armbänder" className={isActive('/jewelry?type=Armbänder') ? 'active' : ''} showOverlay={showOverlay}>
              <span className="dropdown-index">04</span> Armbänder
            </CustomLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default Menubar;
